
    .header{
        text-align: center;
        width: 100%;
        padding-top:30%;
    }
    
    .content{
        text-align: center;
        width: 100%;
        color: white;
    }
    
    .content-1{
        font-family: 'League Spartan', sans-serif;
        font-size: 20px;
        color: #00EF9B;
        padding-bottom: 30px;
        margin-top: -10px;
    }
    
    .content-2{
        padding-top: 8px;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        text-align: left;
        padding-left: 30%;
    }
    
    .content-2-span{
        padding-left: 15px;
    }


@media only screen and (max-width: 1300px) {
    .header{
        text-align: center;
        width: 100%;
        padding-top:40%;
    }
    
    .content{
        text-align: center;
        width: 100%;
        color: white;
    }
    
    .content-1{
        font-family: 'League Spartan', sans-serif;
        font-size: 20px;
        color: #00EF9B;
        padding-bottom: 40px;
        margin-top: -10px;
    }
    
    .content-2{
        padding-top: 8px;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        text-align: left;
        padding-left: 20%;
    }
    
    .content-2-span{
        padding-left: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 979px){
    .header{
        text-align: center;
        width: 100%;
        padding-top:25%;
    }
    
    .content{
        text-align: center;
        width: 100%;
        color: white;
    }
    
    .content-1{
        font-family: 'League Spartan', sans-serif;
        font-size: 20px;
        color: #00EF9B;
        padding-bottom: 40px;
        margin-top: -10px;
    }
    
    .content-2{
        padding-top: 8px;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        text-align: left;
        padding-left: 20%;
    }
    
    .content-2-span{
        padding-left: 15px;
    }
}



@media (max-width: 767px) and (min-width:400px) {
    .header{
        text-align: center;
        width: 100%;
        padding-top:25%;
    }
    
    .content{
        text-align: center;
        width: 100%;
        color: white;
    }
    
    .content-1{
        font-family: 'League Spartan', sans-serif;
        font-size: 20px;
        color: #00EF9B;
        padding-bottom: 40px;
        margin-top: -10px;
        padding-left: 20px;
    }
    
    .content-2{
        visibility: hidden;
    }
    
  }

  @media (max-width: 399px){
    .header{
        text-align: center;
        width: 100%;
        padding-top:25%;
    }
    
    .content{
        text-align: center;
        width: 100%;
        color: white;
    }
    
    .content-1{
        font-family: 'League Spartan', sans-serif;
        font-size: 20px;
        color: #00EF9B;
        padding-bottom: 40px;
        margin-top: -10px;
    }
    
    .content-2{
        visibility: hidden;
    }
    
  }

.login-container {
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    width: 100%;
}


.login-content {
    display: inline-block;
    margin-top: 40%;
    width: 100%;
    text-align: center;
}

.login-title {
    font-size: 35px;
    font-weight: 500;
    /* padding-top: 100px; */
    /* margin-bottom: 30px; */
    font-family: 'Nunito Sans', sans-serif;
}

.info-content{
  font-size: 21px;
  background: #00EF9B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-family: 'League Spartan', sans-serif;
  letter-spacing: -.9px;
}

.newAcct {
  margin-top: 20px;
  align-items: center;
  background-color: transparent;
  border: 2px solid #FFF;
  color: #FFF;
  opacity: 50%;
  font-family: 'League Spartan', sans-serif;
  font-size: 20px;
  height: 40px;
  justify-content: center;
  letter-spacing: -.8px;
  text-align: center;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
}


.newAcct:hover {
  opacity: 100%;
  border: 2px solid #00EF9B;
  color: #00EF9B;
}



.outer-container{
  height: calc(100vh);
  width: calc(100vw); 
  overflow:hidden; 
  margin:0;
  padding:0;
}

.left-container{
  float:right; 
  width: 60%;
  height: 100%;
  background-image: url(imgs/left_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
 
}

.right-container{
  float:left; 
  width: 40%;
  height: 100%;
  background-color: #000000;
  background-image: radial-gradient(circle at top left,#161616c9, #3f3e3e);
}


@media screen and (min-width: 768px) and (max-width: 843px) {

.left-container{
  width: 100%;
  height: 50%;
  background-image: url(imgs/left_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
 
}

.right-container{
  width: 100%;
  height: 50%;
  background-color: #000000;
  background-image: radial-gradient(circle at top left,#161616c9, #3f3e3e);
}
}


@media (max-width: 767px) {
  .left-container{
    width: 100%;
    height: 30%;
    background-image: url(imgs/left_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
   
  }
  
  .right-container{
    width: 100%;
    height: 70%;
    background-color: #000000;
    background-image: radial-gradient(circle at top left,#161616c9, #3f3e3e);
  }
}



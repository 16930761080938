/* .outer-container{
    font-family: 'Poppins', sans-serif;
} */

.reSend{
    background-color: transparent;
    border: none;
    color: #00EF9B;
    text-decoration:underline;
    padding-top: 20px;
}

.warning{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    /* letter-spacing: -.8px; */
}

.timer{
    color: rgb(194, 177, 177);
    font-size: 15px;
    letter-spacing: -.8px;
}

.count{
    font-style: italic;
}


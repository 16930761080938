.login-container {
    text-align: center;
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    align-items: center;
}


.login-content {
    /* box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27); */
    border-radius: 2px;
    width: 500px;
    display: inline-block;
    margin-top: 40%;
    vertical-align: middle;
    position: relative;
    padding: 35px;
    align-items: center;
    align-content: center;
}


.login-title {
    font-size: 29px;
    /* margin-top: 0;
    margin-bottom: 20px; */
    color: white;
    font-family: 'Nunito Sans', sans-serif;
}

.form-item {
    padding-top: 10px;
}

.signup-btn {
    margin-top: 20px;
    width: 50%;
    background-color: transparent;
    box-shadow: 0 1px 5px #171817c7;
    color: white;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
}


.signup-btn:hover {
    background-color: #00EF9B;

}

.or-separator {
    padding: 0 20px 0;
    margin: 20px 0;
    line-height: 1px;
    border-left: 180px solid rgba(221, 221, 221, 0.233);
    border-right: 190px solid rgba(221, 221, 221, 0.233);
    text-align: center;
    color: rgba(221, 221, 221, 0.233);
}


.policy {
    color: gray;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    width: 70%;
    margin-left: 80px;
    text-align: left;
    /* padding-top: 5px; */
}

a {
    color: #00EF9B;
}



.firebaseui-container {
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
}

/* @media screen and (min-width: 768px) {
    .firebaseui-container {
        max-width: 100% !important;
    }
}

@media screen and (min-width: 1240px) {
    .firebaseui-container {
        max-width: 100% !important;
    } */
/* } */

/* .firebaseui-idp-icon {
height: 3vmin !important;
width: 3vmin !important;
} */

/* .firebaseui-tos,
.firebaseui-tospp-full-message {
font-size: 1.5vmin !important;
} */

.firebaseui-idp-button,
.mdl-button,
.mdl-js-button,
.mdl-button--raised,
.firebaseui-idp-google,
.firebaseui-id-idp-button {
    /* align-items: center !important; */
    /* justify-content: center !important; */
    max-width: 85% !important;
}

.firebaseui-idp-text,
.firebaseui-idp-text-long {
    /* font-size: 3vmin !important; */
    color: #FFF !important;
    /* font-family:  'Nunito Sans', sans-serif !important; */
}


@media only screen and (max-width: 1300px) {
    .login-container {
        text-align: center;
        padding-top: 25%;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
        align-items: center;
    }
    
    
    .login-content {
        /* box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27); */
        border-radius: 2px;
        width: 500px;
        display: inline-block;
        margin-top: 30px;
        vertical-align: middle;
        position: relative;
        padding: 35px;
        align-items: center;
        align-content: center;
    }
    
    
    .login-title {
        font-size: 29px;
        margin-top: 0;
        margin-bottom: 20px;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
    }
    
    .form-item {
        padding-top: 10px;
    }
}


@media (max-width: 1024px){
    .login-container {
        text-align: center;
        padding-top: 20%;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
        align-items: center;
        vertical-align: center;
        margin-left: -30px;
        width: 100%;
    }
    
    
    .login-content {
        width: 500px;
        display: inline-block;
        margin-top: 30px;
        vertical-align: middle;
        position: relative;
        padding: 35px;
        align-items: center;
        align-content: center;
    }
    
    
    .login-title {
        font-size: 29px;
        margin-top: 0;
        margin-bottom: 20px;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
    }
    
    .form-item {
        padding-top: 10px;
    }
    
  }

  @media (max-width: 820px){
    .login-container {
        text-align: center;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
        align-items: center;
        vertical-align: center;
        width: 100%;
        height: 100%;
        margin-left: 5px;
    }
    
    
    .login-content {
        width: 500px;
        display: inline-block;
        margin-top: 30px;
        vertical-align: middle;
        position: relative;
        padding: 35px;
        align-items: center;
        align-content: center;
    }
    
    
    .login-title {
        margin-top: -150px;
        font-size: 29px;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
    }
    
    .form-item {
        padding-top: 10px;
    }
    
  }

  @media (max-width: 415px){
    .login-container {
        text-align: center;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
        align-items: center;
        vertical-align: center;
    }
    
    
    .login-content {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        align-items: center;
        align-content: center;
        padding-right: 35%;
    }
    
    
    .login-title {
        margin-top: -80px;
        font-size: 29px;
        color: white;
        font-family: 'Nunito Sans', sans-serif;
    }
    
    .form-item {
        padding-top: 10px;
        padding-left: 100px;
    }

    .policy {
        color: gray;
        font-size: 15px;
        font-family: 'Roboto', sans-serif;
        width: 70%;
        text-align: left;
        margin-left: 15%;
    }
    
  }